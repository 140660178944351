import { Permission } from "@/shared/permission";
import { UtilsString } from "@/utils/utils-string";
import { MenuItem } from "./menu-item";
// Cada opción tendrá indicado:
// - ó el "text" con el texto a mostrar (si no se quiere usar multiidioma)
export const MenuOptions: MenuItem[] = [
  {
    icon: "",
    icon_fntaws: "fad fa-tachometer-fast",
    text: "Inicio",
    color: "#673ab7",
    link: "/main/home",
    permission: UtilsString.Empty,
    children: [],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-users-cog",
    text: "Administración",
    color: "#E9BBA2",
    permission: UtilsString.Empty,
    link: "/main/administracion/",
    children: [
      {
        icon: "",
        icon_fntaws: "fad fa-users",
        text: "Usuarios",
        color: "#4C6EF5",
        permission: UtilsString.Empty,
        link: "/main/usuarios/lista",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-paper-plane",
        text: "Notificaciones",
        color: "#FD7E14",
        permission: UtilsString.Empty,
        link: "/main/notificaciones/",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-user-tag",
        text: "Roles",
        color: "#BE4BDB",
        link: "/main/rol/lista/",
        permission: UtilsString.Empty,
        children: [],
      },
    ],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-box-check",
    text: "Plataforma",
    color: "#93D543",
    permission: "developper",
    link: "/main/configuracion_plataforma/",
    children: [
      {
        icon: "",
        icon_fntaws: "fad fa-table",
        text: "Tablas",
        color: "#4C6EF5",
        permission: UtilsString.Empty,
        link: "/main/tablas/lista",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-database",
        text: "Recursos",
        color: "#F3D260",
        permission: UtilsString.Empty,
        link: "/main/recursos/lista",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-sensor",
        text: "Sensores",
        color: "#F38736",
        permission: UtilsString.Empty,
        link: "/main/sensores/lista",
        children: [],
      },
      {
        icon: "",
        icon_fntaws: "fad fa-sensor-smoke",
        text: "Dispositivos",
        color: "#EA645A",
        permission: UtilsString.Empty,
        link: "/main/dispositivos/lista",
        children: [],
      },{
        icon: "",
        icon_fntaws: "fad fa-signal-4",
        text: "Dashboards",
        color: "#9C27B0",
        permission: UtilsString.Empty,
        link: "/main/dashboards/lista",
        children: [],
      },
    ],
  },
  {
    icon: "",
    icon_fntaws: "fad fa-sign-out-alt",
    text: "Cerrar sesión",
    permission: UtilsString.Empty,
    color: "#C6C6C5",
    link: "/main/cerrar_session",
    children: [],
  },
];
